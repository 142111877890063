import {Component, EventEmitter, Output} from '@angular/core'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() application = new EventEmitter()

  /**
   * The constructor
   * @param configService
   */
  constructor(
    public configService: ConfigService
  ) {
  }

}
