<div class="main">
  <spb-header class="no-print"></spb-header>
  <router-outlet></router-outlet>
  <div [style.flex]="1"></div>
  <spb-footer
    class="no-print"
    (logoutUser)="configService.logout()"
    [license]="true"
    [token]="(configService.accessToken$ | async)"
    [version]="version"
    copyrightYears="2020-2024"
  ></spb-footer>
</div>